import volkswagenLogo from "../assets/images/volkswagenag-grey.png";
import React, {ReactElement} from "react";
import {Link} from "react-router-dom";

interface HeaderProps {
    withShadow?: boolean
}

const Header = (props: HeaderProps): ReactElement => {
    return (
        <header className={props.withShadow ? 'shadow' : ''}>
            <Link to='/app' aria-label='link-to-home'>
                <img className='logo' src={volkswagenLogo} alt='360° Volkswagen Group - Icon'/>
            </Link>
        </header>
    );
}

export default Header;
