import Footer from '../components/footer';
import Header from '../components/header';
import {useTranslation} from "react-i18next";
import QRCode from "react-qr-code";
import React, {ReactElement} from "react";

const Iphone = (): ReactElement => {
    const {t} = useTranslation();

    return (
        <section className='content'>
            <Header withShadow={true}/>

            <section className='iphone-container'>
                <section className='iphone-content'>
                    <article className='content-box'>
                        <h2>{t('iphoneContentPcHeadline')}</h2>
                        <p>{t('iphoneContentPc')}</p>
                        <QRCode value='https://apps.apple.com/de/app/360-volkswagen-app/id1446565623'
                                level={"M"}
                                size={110}
                                role='qr-code'
                                className='qrcode'
                                aria-label='iphone-download-qr-code'/>
                    </article>
                    <section className='iphone-seperator'/>
                    <article className='content-box'>
                        <h2>{t('iphoneContentPhoneHeadline')}</h2>
                        <p>{t('iphoneContentPhone')}</p>
                        <a href='https://apps.apple.com/de/app/360-volkswagen-app/id1446565623'
                           className='btn'>{t('iphoneContentDownloadNow')}</a>
                    </article>
                </section>
            </section>

            <Footer footerClass={'darkFooter'} linkClass={'darkLink'}/>
        </section>
    );
};

export default Iphone;
