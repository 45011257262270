import Header from '../components/header';
import {useTranslation} from "react-i18next";
import React, {ReactElement} from "react";

const DataProtection = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <section className='container'>
            <Header withShadow={true}/>

            <section className='legal-container'>
                <h1> {t('dataProtectionHeadline')}</h1>
                <section dangerouslySetInnerHTML={{__html: t('dataProtection', {interpolation: {escapeValue: true}})}} />
            </section>
        </section>
    );
};

export default DataProtection;
