import Footer from '../components/footer';
import Header  from '../components/header';
import React, {ReactElement} from 'react';
import { useTranslation } from "react-i18next";
import appIcon from "../assets/images/volkswagen-icon-80.png";
import appTitle from "../assets/images/360-volkswagen-app_white.png";
import '../reset.css';
import '../style.css'; // Styles need to be in the correct order
import {Link} from "react-router-dom";

const LandingPage = (): ReactElement => {
    const { t } = useTranslation();

    return (
        <main>
            <Header/>
            <section className='content'>
                <section className='card-pf'>
                    <img className='app-icon' src={appIcon} alt='360° Volkswagen App - Icon'/>
                    <img className='app-title' src={appTitle} alt='360° Volkswagen App - Logo'/>
                    <h2> {t('downloadAppLabel')}</h2>
                    <nav>
                        <a className='btn' aria-label='android-playstore-link'
                            href='https://play.google.com/store/apps/details?id=de.volkswagen.mitarbeiterapp'>
                            {t('android')}
                        </a>
                        <Link className='btn' to='/app/iphone' aria-label='ios-appstore-link'>
                            { t('iphone') }
                        </Link>
                    </nav>
                </section>
                <Footer footerClass={'footer'} linkClass={'link'}/>
            </section>
            <div role='backgroundImage' className='bg'/>
        </main>
    );
};

export default LandingPage;