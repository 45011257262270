import LandingPage from "../pages";
import {Navigate, Route, Routes} from "react-router-dom";
import Iphone from "../pages/iphone";
import DataProtection from "../pages/dataProtection";
import ThirdPartyLicences from "../pages/thirdPartyLicences";
import React, {ReactElement} from "react";
import NewsLinkHandler from "../pages/newsLinkHandler";


const LandingPageRoutes = (): ReactElement => {
    return (
        <Routes>
            <Route path='/app/iphone' element={<Iphone/>}/>
            <Route path='/app/dataProtection' element={<DataProtection/>}/>
            <Route path='/app/thirdPartyLicences' element={<ThirdPartyLicences/>}/>
            <Route path='/app' element={<LandingPage/>}/>
            <Route path='/.well-known/apple-app-site-association' />
            <Route path='/.well-known/assetlinks.json' />
            <Route path='/share/news' element={ <NewsLinkHandler/>} />
            <Route path='*' element={ <Navigate to='/app' /> }/>
        </Routes>
    )
};

export default LandingPageRoutes;