import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import HttpApi from 'i18next-http-backend';

i18next
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .use(HttpApi)
    .init({
            load: 'languageOnly',
            fallbackLng: 'en',
            preload: ['en', 'de'],
            interpolation: { escapeValue: false },
            react: {
                useSuspense: false
            },
            backend: {
                loadPath: '/locales/{{lng}}.json',
            }
        },
        (error) => {
            if (error) {
                console.log(error);
            }
        }
    );

export default i18next;