import React from "react";
import {BrowserRouter} from "react-router-dom";
import './i18n/i18n.js';
import LandingPageRoutes from "./navigation/landingPageRoutes";
import ReactDOM from "react-dom/client";

const container = document.getElementById('root');

if (container === null) throw new Error('Root container missing in index.html');

const root = ReactDOM.createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <LandingPageRoutes/>
        </BrowserRouter>
    </React.StrictMode>
);