import React, {ReactElement} from "react";
import {useSearchParams} from "react-router-dom";
import Header from "../components/header";
import appIcon from "../assets/images/volkswagen-icon-80.png";
import appTitle from "../assets/images/360-volkswagen-app_white.png";
import {useTranslation} from "react-i18next";


const NewsLinkHandler = (): ReactElement => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id")
    const gwDeepLink = `https://volkswagen-net.de/wikis/pages/viewpage.action?pageId=${id}`

    return (
        <main>
            <Header/>
            <section className='content'>
                <section className='card-pf'>
                    <img className='app-icon-small' src={appIcon} alt='360° Volkswagen App - Icon'/>
                    <img className='app-title-small' src={appTitle} alt='360° Volkswagen App - Logo'/>
                    <a className='btn'
                       aria-label='open-link-in-browser'
                       href={gwDeepLink}>
                        {t('openLinkInBrowser')}
                    </a>
                    <h2> {t('downloadAppLabel')}</h2>
                    <a className='btn-secondary'
                       aria-label='android-playstore-link'
                       href='https://play.google.com/store/apps/details?id=de.volkswagen.mitarbeiterapp'>
                        {t('android')}
                    </a>
                    <a className='btn-secondary'
                       aria-label='ios-appstore-link'
                       href='https://apps.apple.com/de/app/360-volkswagen-app/id1446565623'>
                        {t('iphone')}
                    </a>
                </section>
            </section>
            <div role='backgroundImage' className='bg'/>
        </main>
    );
}

export default NewsLinkHandler;