import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import React, {ReactElement} from "react";

interface FooterProps {
    footerClass: string,
    linkClass: string
}

const Footer = (props: FooterProps): ReactElement => {
    const {t} = useTranslation();

    return (
        <footer className={props.footerClass}>
            <nav>
                <a className={props.linkClass} href={t('imprintLink') as string}>
                    {t('imprint')}
                </a> | <a className={props.linkClass} href='https://www.volkswagenag.com/de/meta/condition-of-use.html'>
                {t('termsOfUseLink')}
                </a>
            </nav>
            <nav>
                <Link className={props.linkClass} to='/app/dataProtection'>
                    {t('dataProtectionHeadline')}
                </Link> | <Link className={props.linkClass} to='/app/thirdPartyLicences'>
                {t('thirdPartyLicencesHeadline')}
                </Link>
            </nav>
        </footer>
    );
};

export default Footer;