import Header from '../components/header';
import {useTranslation} from "react-i18next";
import React, {ReactElement} from "react";

const ThirdPartyLicences = (): ReactElement => {
    const {t} = useTranslation();

    return (
        <section className='container'>
            <Header withShadow={true}/>

            <section className='legal-container'>
                <h1> {t('thirdPartyLicencesHeadline')} </h1>
                <section dangerouslySetInnerHTML={{__html: t('thirdPartyLicences', {interpolation: {escapeValue: true}})}}/>
            </section>
        </section>
    );
}

export default ThirdPartyLicences;
